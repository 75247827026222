import Contact from "@/entity/Contact";
import User from "@/entity/User";
import Project from "@/entity/Project";
import Tag from "@/entity/Tag";

export enum HistoryTypes {
  BASELINE = "BASELINE",
  MANUAL = "MANUAL",
  EXPOSE_ORDERED = "EXPOSE_ORDERED",
  EXPOSE_SENT = "EXPOSE_SENT",
  PROJECT_CREATED = "PROJECT_CREATED",
  PROJECT_CHANGED = "PROJECT_CHANGED",
  CONTACT_CREATED = "CONTACT_CREATED",
  CONTACT_CHANGED = "CONTACT_CHANGED",
  CONTACT_NDA_SENT = "CONTACT_NDA_SENT",
  CONTACT_NDA_SIGNED = "CONTACT_NDA_SIGNED",
  CONTACT_NDA_APPROVED = "CONTACT_NDA_APPROVED",
  CONTACT_NDA_REJECTED = "CONTACT_NDA_REJECTED",
  CONTACT_NDA_INVALIDATED = "CONTACT_NDA_INVALIDATED",
  CONTACT_ADDED_TO_BUYERS = "CONTACT_ADDED_TO_BUYERS",
  CONTACT_REMOVED_FROM_BUYERS = "CONTACT_REMOVED_FROM_BUYERS",
  CONTACT_DELETED = "CONTACT_DELETED",
  TEASER_SENT = "TEASER_SENT",
  TEASER_ACCEPTED = "TEASER_ACCEPTED",
  TEASER_DISMISSED = "TEASER_DISMISSED",
  SEARCH_PROFILE_CREATED = "SEARCH_PROFILE_CREATED",
  SEARCH_PROFILE_DELETED = "SEARCH_PROFILE_DELETED",
  PURCHASE_OFFER_CHANGED = "PURCHASE_OFFER_CHANGED",
  ATTACHMENT_CREATED = "ATTACHMENT_CREATED",
  ATTACHMENT_DELETED = "ATTACHMENT_DELETED",
  CONTACT_BLOCKED = "CONTACT_BLOCKED",
  CONTACT_UNBLOCKED = "CONTACT_UNBLOCKED",
  CONTACT_DISABLED = "CONTACT_DISABLED",
  CONTACT_DISABLED_REGISTRATION = "CONTACT_DISABLED_REGISTRATION",
  CONTACT_ENABLED = "CONTACT_ENABLED",
  EXPRESSION_OF_INTEREST_SENT = "EXPRESSION_OF_INTEREST_SENT",
  EXPRESSION_OF_INTEREST_FILLED = "EXPRESSION_OF_INTEREST_FILLED",
  EXPRESSION_OF_INTEREST_CHANGED = "EXPRESSION_OF_INTEREST_CHANGED",
  EXPOSE_REJECTED = "EXPOSE_REJECTED",
}

export interface HistoryChange {
  key: string;
  nextState: any;
  previousState: any;
}

export interface CreateHistory {
  projectId: null | number;
  contactId: null | number;
  creatorId: null | number;
  historyDate: number;
  note: string;
  tags: number[];
  eventType: HistoryTypes;
  meta: string;
}

export default interface History {
  id?: number;
  historyDate: number;
  creatorId?: number | null;
  contactId: number | null;
  projectId: number | null;
  createdAt: number;
  updatedAt?: number;
  note: string;
  tags: number[];
  eventType: HistoryTypes;
  creator: User | null;
  contact: Contact | null;
  project: Project | null;
  meta: string | null;
  hasAttachments: boolean;
}

export const tagIdsToNames = (tags: Tag[], tagIds: number[]): string[] => {
  if (!Array.isArray(tags) || !Array.isArray(tagIds)) {
    return [];
  }

  const names: string[] = [];

  tagIds.forEach((id) => {
    const tag = tags.find((tag) => {
      return tag.id === id;
    });

    if (tag) {
      names.push(tag.name);
    }
  });

  return names;
};
